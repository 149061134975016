export const environment = {
	production: true,
	buildingApiUrl: "https://wxs.ign.fr/parcellaire/geoportail/wfs",
	elevationApiUrl: "https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevation.json",
	oldElevationApiUrl: "https://wxs.ign.fr/essentiels/alti/rest/elevation.json",
	baseApiUrl: "https://api.decret-tertiaire.greensystemes.com",
	addressSearchApiUrl: "https://api-adresse.data.gouv.fr/search",
	parcelleApiUrl: "https://apicarto.ign.fr/api/cadastre/parcelle",
	stripePublicApiKey: "pk_live_FNf2iKk3UA2882Lodnpm94wg00PyzfbXHi",
	version: "2.29.0",
	masterId: "62557981f934d55451008a73",
};
